import * as React from "react";
import { UitkText } from "@egds/react-core/text";
import { UitkSpacing, UitkSpacingProps } from "@egds/react-core/spacing";
import { useLocalization } from "@shared-ui/localization-context";
import { PackageConfig } from "stores/wizard/config";

export interface ValuePropSubtitleHotwireProps {
  config: PackageConfig;
  margin?: UitkSpacingProps["margin"];
  whiteText?: boolean;
}

export const ValuePropSubtitleHotwire: React.FC<ValuePropSubtitleHotwireProps> = ({ config, margin, whiteText }) => {
  const { formatText } = useLocalization();

  return (
    <UitkSpacing margin={margin}>
      <UitkText size={400} theme={whiteText ? "inverse" : "default"}>
        {formatText(config.pillsLabelKey)}
      </UitkText>
    </UitkSpacing>
  );
};
