import { PackageHiddenSearchDataHotwireProps } from "components/flexComponents/WizardPackagePWA/typings";
import * as React from "react";
import { observer } from "mobx-react";
import { PackagesSubLobs } from "src/stores/wizard/state/typings";
import { useLocalization } from "@shared-ui/localization-context";
import { InfantSeating } from "src/components/shared/TravelersField";

const getVacationCodes = (pwaSubLOB: string) => {
  switch (pwaSubLOB) {
    case PackagesSubLobs.FLIGHT_CAR: {
      return "AC";
    }
    case PackagesSubLobs.HOTEL_CAR: {
      return "CH";
    }
    case PackagesSubLobs.FLIGHT_HOTEL_CAR: {
      return "ACH";
    }
    default: {
      return "AH";
    }
  }
};

export const PackageHiddenSearchDataHotwire = observer((props: PackageHiddenSearchDataHotwireProps) => {
  const {
    pwaSubLOB,
    packageWizardState: {
      location: {
        origin: {
          metaData: { ttla: originAirportCode },
        },
        destination: {
          metaData: { ttla: destinationAirportCode },
        },
      },
      travelers,
      packageDates,
      partialStayDate,
      partialStay,
    },
  } = props;
  const { formatDate } = useLocalization();
  const isCarSelected = () => pwaSubLOB !== PackagesSubLobs.FLIGHT_HOTEL;

  return (
    <div>
      <input type="hidden" name="origCity" value={originAirportCode} data-testid="origCity" />
      <input type="hidden" name="destCity" value={destinationAirportCode} data-testid="destCity" />
      <input
        type="hidden"
        name="startDate"
        value={formatDate(packageDates.start, { raw: "MM/dd/yyyy" })}
        data-testid="startDate"
      />
      <input
        type="hidden"
        name="endDate"
        value={formatDate(packageDates.end, { raw: "MM/dd/yyyy" })}
        data-testid="endDate"
      />
      {partialStay && (
        <>
          <input
            type="hidden"
            name="partialHotelStayStartDate"
            value={formatDate(partialStayDate.start, { raw: "MM/dd/yyyy" })}
            data-testid="partialHotelStayStartDate"
          />
          <input
            type="hidden"
            name="partialHotelStayEndDate"
            value={formatDate(partialStayDate.end, { raw: "MM/dd/yyyy" })}
            data-testid="partialHotelStayEndDate"
          />
          <input
            type="hidden"
            name="isPartialHotelStaySelected"
            value="true"
            data-testid="isPartialHotelStaySelected"
          />
        </>
      )}
      <input
        type="hidden"
        name="vacationPGoodTypeCodes"
        value={getVacationCodes(pwaSubLOB)}
        data-testid="vacationTypeCode"
      />
      <input type="hidden" name="inputId" value="index" data-testid="inputIdForTracking" />
      {isCarSelected() && (
        <>
          <input type="hidden" name="vacationStartTime" value="Morning" data-testid="vacationStartTime" />
          <input type="hidden" name="vacationEndTime" value="Morning" data-testid="vacationEndTime" />
          <input type="hidden" name="vacationSimpleStartTime" value="5am" data-testid="vacationSimpleStartTime" />
          <input type="hidden" name="vacationSimpleEndTime" value="8pm" data-testid="vacationSimpleEndTime" />
        </>
      )}
      <input
        type="hidden"
        name="areInfantsInSeats"
        value={`${travelers.hotelPackage.infantSeating === InfantSeating.IN_SEAT}`}
        data-testid="areInfantsInSeats"
      />
      <input type="hidden" name="rs" value="0" data-testid="rs" />
      <input type="hidden" name="isFromFareFinder" value="true" data-testid="isFromFareFinder" />
      <input type="hidden" name="shouldSkipForward" value="true" data-testid="shouldSkipForward" />
    </div>
  );
});
