import { PackageSubLOBProps } from "components/flexComponents/WizardPackagePWA/typings";
import * as React from "react";
import { observer } from "mobx-react";
import { locKeys } from "components/flexComponents/WizardPackagePWA/l10n";
import { Location } from "src/components/shared/Location/Location";
import { Dates } from "src/components/shared/Dates/Dates";
import { PackagePartialStay } from "components/flexComponents/WizardPackagePWA/components/PackagePartialStay";
import { UitkLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";

export const FlightHotelDefault = observer(
  ({ packageWizardState, setInputsRefs, togglePartialStay, globalConfig }: PackageSubLOBProps) => {
    const halfWizard = globalConfig?.halfWizard;
    const locationColSpan: UitkLayoutConditionalGridSpan = { small: 2, medium: 12, large: halfWizard ? 12 : 8 };
    const datesColSpan: UitkLayoutConditionalGridSpan = { small: 2, medium: 12, large: halfWizard ? 12 : 4 };
    const datesPartialStayColSpan: UitkLayoutConditionalGridSpan = { small: 2, medium: 12, large: halfWizard ? 12 : 8 };

    const { isFlightHotelDefaultFormValid, updateInvalidFormsState } = packageWizardState;

    if (isFlightHotelDefaultFormValid) {
      updateInvalidFormsState();
    }

    const renderPartialDates = ({ stackedDates = false }: { stackedDates?: boolean }) => (
      <Dates
        lobState={packageWizardState}
        isPartialStay
        colSpan={datesPartialStayColSpan}
        startId="d1-partial"
        endId="d2-partial"
        setInputsRefs={setInputsRefs}
        stackedDates={stackedDates}
      />
    );

    return (
      <>
        <Location
          originField
          destinationField
          lobState={packageWizardState}
          colSpan={locationColSpan}
          setInputsRefs={setInputsRefs}
          showSwapLocationsControl
          globalConfig={globalConfig}
        />

        <Dates lobState={packageWizardState} colSpan={datesColSpan} setInputsRefs={setInputsRefs} />

        <PackagePartialStay
          togglePartialStay={togglePartialStay}
          packageWizardState={packageWizardState}
          partialStayAccommodationLabel={locKeys.partialStayAccommodationLabel}
        />

        {packageWizardState.partialStay && (
          <Viewport>
            <ViewSmall>{renderPartialDates({ stackedDates: true })}</ViewSmall>
            <ViewMedium>{renderPartialDates({})}</ViewMedium>
          </Viewport>
        )}
      </>
    );
  }
);
