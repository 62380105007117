import * as React from "react";
import { observer } from "mobx-react";

import { Dates } from "components/shared/Dates/Dates";
import { Location } from "components/shared/Location/Location";
import { SubmitButton } from "components/shared/SubmitButton/SubmitButton";
import { OffsetGridItem } from "components/shared/OffsetGridItem/OffsetGridItem";
import { useMultiLOBWizardRegion } from "components/flexComponents/WizardPWARegions/WizardRegionHotwire/WizardRegionContextProvider/MultiLOBWizardRegionContext";
import { PackagePartialStay } from "../../components/PackagePartialStay";
import { PackageSubLOBHotwireProps } from "../../typings";
import { getHotwireFlightHotelColSpans } from "../../utils/hotwirePackageOverrides";
import { MenuFieldsHotwire } from "./MenuFieldsHotwire";

export const FlightHotelCarHotwire = observer((props: PackageSubLOBHotwireProps) => {
  const { isMultiLOB } = useMultiLOBWizardRegion();

  const {
    packageWizardState: {
      isFlightHotelCarFormValid,
      updateInvalidFormsState,
      partialStay,
      config: {
        travelers: { noRehydratationValues },
      },
    },
  } = props.wizardState;

  if (isFlightHotelCarFormValid) {
    updateInvalidFormsState();
  }

  const {
    locationColSpan,
    datesColStart,
    datesColEnd,
    datesPartialStayColSpan,
    submitColSpan,
    multiLobNonPartialOffsetColSpan,
  } = getHotwireFlightHotelColSpans({ isMultiLOB, partialStay });

  return (
    <>
      <Location
        setInputsRefs={props.setInputsRefs}
        originField
        destinationField
        lobState={props.packageWizardState}
        colSpan={locationColSpan}
        showSwapLocationsControl={false}
        globalConfig={props.globalConfig}
      />
      <Dates
        lobState={props.packageWizardState}
        colStart={datesColStart}
        colEnd={datesColEnd}
        setInputsRefs={props.setInputsRefs}
      />
      <MenuFieldsHotwire
        currentSubLOB={props.currentSubLOB}
        wizardState={props.wizardState}
        noRehydratationValues={noRehydratationValues}
      />
      <PackagePartialStay
        togglePartialStay={props.togglePartialStay}
        packageWizardState={props.packageWizardState}
        partialStayAccommodationLabel="hotwire.wizard.package.partialStay.accommodation.checkbox.label"
      />
      {partialStay && (
        <Dates
          lobState={props.packageWizardState}
          isPartialStay
          colSpan={datesPartialStayColSpan}
          stackedDates={false}
          setInputsRefs={props.setInputsRefs}
        />
      )}
      {!partialStay && isMultiLOB && <OffsetGridItem colSpan={multiLobNonPartialOffsetColSpan} />}
      <SubmitButton
        lobState={props.packageWizardState}
        rfrr="SearchClick_Package"
        colSpan={submitColSpan}
        globalConfig={props.globalConfig}
      />
    </>
  );
});
