import * as React from "react";

import { useLocation } from "react-router-dom";
import { ClientWizardStateStore } from "src/stores/wizard/ClientWizardStateStore";

import { UitkLayoutConditionalGridStartEnd } from "@egds/react-core/layout-grid";
import { Travelers } from "src/components/shared/Travelers/Travelers";
import { NoRehydratationValues } from "src/components/shared/TravelersField/typings";
import { PackagesSubLobs } from "src/stores/wizard/state/typings";
import { useMultiLOBWizardRegion } from "components/flexComponents/WizardPWARegions/WizardRegionHotwire/WizardRegionContextProvider/MultiLOBWizardRegionContext";

export type MenuFieldsHotwireProps = {
  currentSubLOB: string;
  wizardState: ClientWizardStateStore;
  noRehydratationValues?: NoRehydratationValues;
  colStart?: UitkLayoutConditionalGridStartEnd;
  colEnd?: UitkLayoutConditionalGridStartEnd;
};

export const MenuFieldsHotwire: React.FC<MenuFieldsHotwireProps> = ({
  currentSubLOB,
  wizardState,
  noRehydratationValues,
  colStart,
  colEnd,
}) => {
  const { packageWizardState } = wizardState;
  const { config: packageConfig } = packageWizardState;
  const { isMultiLOB } = useMultiLOBWizardRegion();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const travelersColStart: UitkLayoutConditionalGridStartEnd = { small: 1, medium: 7, large: isMultiLOB ? 7 : 5 };
  const travelersColEnd: UitkLayoutConditionalGridStartEnd = {
    small: "end",
    medium: "end",
    large: isMultiLOB ? "end" : 9,
  };

  return (
    <>
      {currentSubLOB === PackagesSubLobs.FLIGHT_HOTEL && (
        <Travelers
          tripLobType={queryParams.get("pwaSubLOB") || undefined}
          lobState={packageWizardState}
          colStart={travelersColStart}
          colEnd={travelersColEnd}
          noRehydratationValues={noRehydratationValues}
        />
      )}

      {currentSubLOB === PackagesSubLobs.FLIGHT_CAR && (
        <Travelers
          tripLobType={queryParams.get("pwaSubLOB") || undefined}
          lobState={packageWizardState}
          colStart={travelersColStart}
          colEnd={travelersColEnd}
          noRehydratationValues={{
            numOfTravelers: 1,
            name: "adultsPerRoom[1]",
          }}
          config={packageConfig.subLOB.flightCar.travelers}
        />
      )}

      {currentSubLOB === PackagesSubLobs.FLIGHT_HOTEL_CAR && (
        <Travelers
          tripLobType={queryParams.get("pwaSubLOB") || undefined}
          lobState={packageWizardState}
          colStart={travelersColStart}
          colEnd={travelersColEnd}
          noRehydratationValues={noRehydratationValues}
        />
      )}

      {currentSubLOB === PackagesSubLobs.HOTEL_CAR && (
        <Travelers
          tripLobType={queryParams.get("pwaSubLOB") || undefined}
          lobState={packageWizardState}
          config={packageConfig.subLOB.hotelCar.travelers}
          colStart={colStart || travelersColStart}
          colEnd={colEnd || travelersColEnd}
          noRehydratationValues={noRehydratationValues}
        />
      )}
    </>
  );
};
