import * as React from "react";
import { inject, observer } from "mobx-react";
import {
  WizardPackagePWAViewProps,
  WizardPackagePWAViewState,
  PackageTab,
  WizardPackagePWAFlexModelResult,
  TabsLabels,
} from "../typings";
import { PackageLobTitle } from "src/components/shared/LobTitle/PackageLobTitle";
import { locKeys } from "src/components/flexComponents/WizardPackagePWA/l10n";
import { LobSubtitle } from "src/components/shared/LobSubtitle/LobSubtitle";
import { PackageForm } from "../components/PackageForm";
import {
  UitkExperimentalButtonTabs,
  UitkTab,
  UitkTabsType,
  UitkTabAnchorType,
} from "uitk-react-experimental-button-tabs";
import { withLocalization } from "@shared-ui/localization-context";
import { Wizard3ppPWAView } from "../../Wizard3ppPWA/Wizard3ppPWAView";
import { UitkSpacing } from "@egds/react-core/spacing";

@withLocalization
@inject("compositionStore", "flexModuleModelStore")
@observer
export class WizardPackagePWAView extends React.Component<WizardPackagePWAViewProps, WizardPackagePWAViewState> {
  constructor(props: WizardPackagePWAViewProps) {
    super(props);
    this.state = {
      currentTab: props.defaultTab,
    };
  }

  render() {
    const {
      packageWizardState,
      thirdPartyPackageWizardState,
      packageWizardState: { config: packageConfig },
      globalWizardState: { config: globalConfig },
    } = this.props.wizardState;
    const { templateComponent, flexModuleModelStore } = this.props;
    const { metadata } = templateComponent;
    const { id } = metadata;
    const model = flexModuleModelStore.getModel(id) as WizardPackagePWAFlexModelResult | null;

    if (model == null) {
      return null;
    }

    const packageWith3PP = model.include3PP ?? globalConfig.packageWith3PP;
    const tabsLabelsEnum: TabsLabels = {
      packages: this.props.l10n.formatText(packageConfig.navigation.subTabTitleToken),
      "all-inclusive": this.props.l10n.formatText(thirdPartyPackageWizardState.config.navigation.tabTitleToken),
    };

    const currentTab = tabsLabelsEnum[this.state.currentTab as keyof TabsLabels];

    const onSelectTab = (tabSelected: string) => {
      if (tabSelected === tabsLabelsEnum["packages"]) {
        this.setState({ currentTab: PackageTab.BundledPackageTab });
      } else {
        this.setState({ currentTab: PackageTab.AllInclusiveTab });
      }
    };

    if (packageWith3PP) {
      packageWizardState.overrideConfig(() => {
        packageWizardState.config.heading.locSubtitleTextToken = locKeys.packageAnd3PPSubtitle;
      });
    }

    const defaultPageHeading = this.props.l10n.formatText(packageWizardState.config.heading.locTitleToken);
    const compositionPageHeading = this.props.compositionStore?.pageHeading;
    const useCompositionHeading = model.includePageHeading || model.titleFromPageHeader;
    const pageHeading = useCompositionHeading && compositionPageHeading ? compositionPageHeading : defaultPageHeading;

    //different messages with/without subLOB tabs
    const defaultPageSubHeading = this.props.wizardState?.globalWizardState?.config?.packageWith3PP
      ? locKeys.packageCheckboxChooseTwo
      : locKeys.pacakgeCheckboxStartBuilding;
    const compositionSubHeadline = this.props.compositionStore?.pageSubHeadline;
    const useCompositionPageSubHeading = model.includePageSubHeading;
    const pageSubHeading =
      useCompositionPageSubHeading && compositionSubHeadline
        ? compositionSubHeadline
        : useCompositionPageSubHeading
        ? this.props.l10n.formatText(defaultPageSubHeading)
        : "";

    return (
      <>
        <PackageLobTitle lobState={packageWizardState} pageHeading={pageHeading} pageSubHeading={pageSubHeading} />

        {packageWith3PP ? (
          <>
            {packageWizardState.config.heading.visibleLobSubtitle && (
              <UitkSpacing margin={{ blockend: "three" }}>
                <LobSubtitle lobState={packageWizardState} />
              </UitkSpacing>
            )}
            <UitkSpacing margin={{ blockstart: "three" }}>
              <div>
                <UitkExperimentalButtonTabs
                  data-testid="PackageThreePPTabs"
                  className="PackageThreePPTabs"
                  selectedTab={currentTab}
                  onTabSelect={onSelectTab}
                  tabsType={UitkTabsType.NATURAL}
                  tabAnchorType={UitkTabAnchorType.BUTTON}
                >
                  <UitkTab name={tabsLabelsEnum.packages} id="Package-vacations" data-testid="Package-vacations">
                    <PackageForm {...this.props} />
                  </UitkTab>
                  <UitkTab name={tabsLabelsEnum["all-inclusive"]} id="All-inclusive-vacations">
                    <Wizard3ppPWAView {...this.props} />
                  </UitkTab>
                </UitkExperimentalButtonTabs>
              </div>
            </UitkSpacing>
          </>
        ) : (
          <>
            <PackageForm {...this.props} />
          </>
        )}
      </>
    );
  }
}
