import { PackageSubLOBProps } from "components/flexComponents/WizardPackagePWA/typings";
import * as React from "react";
import { observer } from "mobx-react";
import { FlightHotelDefault } from "./FlightHotelDefault";
import { PackageOnewayFlight } from "./PackageOnewayFlight";
import { FlightType } from "src/stores/wizard/state/typings";

export const FlightHotel = observer((props: PackageSubLOBProps) => {
  const { packageWizardState } = props.wizardState;
  const flightType = packageWizardState.subLOBFlightTypeState;

  if (
    FlightType.ONE_WAY === flightType &&
    packageWizardState.isDesktop &&
    packageWizardState.experimentNewPackageOnewayFlightType.variant1
  ) {
    return <PackageOnewayFlight {...props} />;
  }

  return <FlightHotelDefault {...props} />;
});
