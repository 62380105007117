import { PackageHiddenSearchDataProps } from "components/flexComponents/WizardPackagePWA/typings";
import * as React from "react";
import { observer } from "mobx-react";
import { PackagesSubLobs } from "src/stores/wizard/state/typings";
import { useLocalization } from "@shared-ui/localization-context";

export const PackageHiddenSearchData = observer((props: PackageHiddenSearchDataProps) => {
  const { formatDate } = useLocalization();
  const rawFormatForSubmission = props.config.date.ISOSubmissionSupport
    ? props.config.date.format
    : props.packageWizardState.localDateFormat;

  const isNotHotelCar = props.packageWizardState.subLOBState !== PackagesSubLobs.HOTEL_CAR;
  const originRegionId = props.packageWizardState.location.origin.metaData.regionId;
  const originTTLA = props.packageWizardState.location.origin.metaData.ttla;
  const destinationRegionId = props.packageWizardState.location.destination.metaData.destinationId;
  const destinationTTLA = props.packageWizardState.location.destination.metaData.ttla;
  const useSomewhereElse =
    props.packageWizardState.isStaySomewhereElse() || props.packageWizardState.driveSomewhereElse;
  const hotelDestinationId = props.packageWizardState.hotelLocation.destination.metaData.regionId;
  const hotelId = props.packageWizardState.location.destination.metaData.hotelId;
  const isFlightHotel = props.packageWizardState.isPackageFlightHotel();
  const isNotRoundtripFlight = !props.packageWizardState.isRoundtripFlight();
  const hasPartialStays =
    props.packageWizardState.subLOBState === PackagesSubLobs.FLIGHT_HOTEL_CAR ||
    props.packageWizardState.subLOBState === PackagesSubLobs.FLIGHT_HOTEL;
  const [disableOriginRegionId, setDisableOriginRegionId] = React.useState(true);
  const [disableOriginTTLA, setDisableOriginTTLA] = React.useState(true);
  const [disableDestiantionRegionId, setDisableDestionationRegionID] = React.useState(true);
  const [disableDestinationTTLA, setDisableDestionationTTLA] = React.useState(true);
  const [disableHotelDestinationId, setDisableHotelDestinationID] = React.useState(true);
  const [disableHotelId, setDisableHotelId] = React.useState(true);
  const [disableTripType, setDisableTripType] = React.useState(true);
  const [disableEndDate, setDisableEndDate] = React.useState(true);

  const { flightClassCode, flightAirline } = props.packageWizardState;
  const [cabinClassValue, setCabinClassValue] = React.useState(isNotHotelCar ? flightClassCode : "");
  const [flightCarrierValue, setFlightCarrierValue] = React.useState(isNotHotelCar ? flightAirline?.value : "");

  const includesHotel = props.packageWizardState.subLOBState !== PackagesSubLobs.FLIGHT_CAR;

  React.useEffect(() => {
    setCabinClassValue(flightClassCode);
  }, [flightClassCode]);

  React.useEffect(() => {
    if (props.packageWizardState.flightAirline?.value) {
      setFlightCarrierValue(flightAirline.value);
    }
  }, [flightAirline]);

  React.useEffect(() => {
    setDisableOriginRegionId(!originRegionId);
  }, [originRegionId]);

  React.useEffect(() => {
    setDisableOriginTTLA(!originTTLA);
  }, [originTTLA]);

  React.useEffect(() => {
    setDisableDestionationRegionID(!destinationRegionId);
  }, [destinationRegionId]);

  React.useEffect(() => {
    setDisableDestionationTTLA(!destinationTTLA);
  }, [destinationTTLA]);

  React.useEffect(() => {
    setDisableHotelDestinationID(!(useSomewhereElse && hotelDestinationId));
  }, [useSomewhereElse, hotelDestinationId]);

  React.useEffect(() => {
    setDisableHotelId(!hotelId);
  }, [hotelId]);

  React.useEffect(() => {
    setDisableTripType(!isFlightHotel);
  }, [isFlightHotel]);

  React.useEffect(() => {
    setDisableEndDate(!isNotRoundtripFlight);
  }, [isNotRoundtripFlight]);

  return (
    <div>
      <input type="hidden" name="packageType" value={props.pwaSubLOB} />
      <input
        type="hidden"
        name="cabinClass"
        value={cabinClassValue}
        data-testid="cabinClass-input"
        disabled={!isNotHotelCar}
      />
      <input
        type="hidden"
        name="flightCarrier"
        value={flightCarrierValue}
        data-testid="flightAirline-input"
        disabled={!isNotHotelCar}
      />
      <input
        type="hidden"
        name="originId"
        value={originRegionId || ""}
        data-testid="originId-input"
        disabled={disableOriginRegionId}
      />
      <input
        type="hidden"
        name="ftla"
        value={originTTLA || ""}
        data-testid="originTLA-input"
        disabled={disableOriginTTLA}
      />
      <input
        type="hidden"
        name="destinationId"
        value={destinationRegionId || ""}
        data-testid="destinationId-input"
        disabled={disableDestiantionRegionId}
      />
      <input
        type="hidden"
        name="ttla"
        value={destinationTTLA || ""}
        data-testid="destinationTLA-input"
        disabled={disableDestinationTTLA}
      />
      <input type="hidden" name="hotelId" value={hotelId || ""} data-testid="hotelId-input" disabled={disableHotelId} />
      <input
        type="hidden"
        name="hotelDestinationId"
        value={hotelDestinationId || ""}
        data-testid="hotelDestinationId-input"
        disabled={disableHotelDestinationId}
      />
      <input
        type="hidden"
        name="tripType"
        data-testid="flight-type-input"
        value={isFlightHotel ? props.packageWizardState.flightTypeCodeForURL : ""}
        disabled={disableTripType}
      />

      <input
        type="hidden"
        name={props.config.date.queryParam.end}
        value={
          isNotRoundtripFlight
            ? formatDate(props.packageWizardState.packageDates.end, { raw: rawFormatForSubmission })
            : ""
        }
        disabled={disableEndDate}
      />
      {hasPartialStays && (
        <input
          type="hidden"
          name="isPartialStay"
          value={props.packageWizardState.partialStay ? 1 : 0}
          data-testid="partial-stay-input"
        />
      )}
      {includesHotel && props.hotelConfig?.hotelChainID && (
        <input type="hidden" name="chain" data-testid="hotel-chain" value={props.hotelConfig.hotelChainID} />
      )}
      {includesHotel && props.hotelConfig?.hotelName && (
        <input type="hidden" name="hotelName" data-testid="hotel-name" value={props.hotelConfig.hotelName} />
      )}
      {includesHotel && props.hotelConfig?.hotelID && (
        <input
          type="hidden"
          name={hasPartialStays || isFlightHotel ? "hotelIds" : "group"}
          data-testid="hotel-group"
          value={props.hotelConfig.hotelID}
        />
      )}
    </div>
  );
});
