// External libraries
import * as React from "react";
import { inject, observer } from "mobx-react";
import { useLocalization } from "@shared-ui/localization-context";

// UITK
import { UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { TypeaheadSelection } from "@egds/react-core/typeahead";

// Components
import { LocationField } from "components/shared/LocationField/LocationField";
import { locKeys } from "components/flexComponents/WizardPackagePWA/l10n";
import { StaySomewhereElseProps } from "../typings";

export const StaySomewhereElse: React.FunctionComponent<StaySomewhereElseProps> = inject("context")(
  observer(({ colSpan, lobState, context }) => {
    const { formatText } = useLocalization();

    /* istanbul ignore next */
    const locationStaySomewhereElse = colSpan || { small: 2, medium: 2, large: 2 };
    const locationConfig = lobState.config.hotelDestination;
    const locationState = lobState.hotelLocation;

    /* istanbul ignore next */
    const onHotelDestinationLocationFieldUpdate = (selection: TypeaheadSelection) => {
      lobState.updateHotelDestinationSelection(selection);
    };

    const invalidMessage = lobState.hotelDestinationInvalidKey ? formatText(lobState.hotelDestinationInvalidKey) : "";

    return (
      <UitkLayoutGridItem colSpan={locationStaySomewhereElse}>
        <div>
          {locationState && (
            <LocationField
              context={context}
              onUpdate={onHotelDestinationLocationFieldUpdate}
              icon={locationConfig.icon}
              name={locationConfig.inputName}
              subLob={lobState.subLOBState}
              label={formatText(locKeys.staySomewhereElseDestinationLabel)}
              typeaheadPlaceholder={formatText(locKeys.staySomewhereElseDestinationPlaceholder)}
              typeaheadEmptyResultPlaceholder={formatText(locationConfig.typeaheadEmptyResultPlaceholder!)}
              invalid={invalidMessage}
              value={locationState.destination.value}
              essAdapterConfig={locationConfig.essAdapterConfig}
              stid={`location-field-${locationConfig.inputName}`}
              config={locationConfig}
              location={locationState.destination}
            />
          )}
        </div>
      </UitkLayoutGridItem>
    );
  })
);
