import * as React from "react";
import { observer } from "mobx-react";

import { useLocalization } from "@shared-ui/localization-context";
import { UitkEmptyState } from "@egds/react-core/empty-state";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { UitkHeading, UitkText } from "@egds/react-core/text";

import { locKeys } from "components/flexComponents/WizardPackagePWA/l10n";
import { GlobalWizardState, LOBState, PackageWizardState } from "stores/wizard/state";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";

export interface PackageErrorStateProps {
  lobsMissing: number;
  packageWizardState: PackageWizardState;
  globalWizardState: GlobalWizardState;
  lobState: LOBState;
  context?: ExtendedContextStore;
}

const { lobErrorHeadingsMap, lobErrorBodyMap, lobErrorHeadingsMapMultiLOB, lobErrorBodyMapMultiLOB } = locKeys;

export const PackageErrorState: React.FunctionComponent<PackageErrorStateProps> = observer(
  ({ context, lobsMissing, packageWizardState, lobState, globalWizardState }) => {
    const { formatText } = useLocalization();

    const { includeSingleLOBsWithinPackages } = globalWizardState.config;

    const heading = formatText(
      includeSingleLOBsWithinPackages ? lobErrorHeadingsMapMultiLOB[lobsMissing] : lobErrorHeadingsMap[lobsMissing]
    );
    let body = formatText(
      includeSingleLOBsWithinPackages ? lobErrorBodyMapMultiLOB[lobsMissing] : lobErrorBodyMap[lobsMissing]
    );
    const { updateInvalidFormsState } = packageWizardState;

    body = lobsMissing === 99 ? formatText(lobState.config.heading.lobNotSupported) : body;

    updateInvalidFormsState();

    return (
      <UitkSpacing margin={{ blockend: "six" }}>
        <UitkLayoutFlex justifyContent="center">
          <UitkEmptyState>
            <UitkIcon name="search" size={UitkIconSize.LARGE} />
            <UitkHeading tag="h2" size={5}>
              {heading}
            </UitkHeading>
            <UitkText>{body}</UitkText>
          </UitkEmptyState>
        </UitkLayoutFlex>
      </UitkSpacing>
    );
  }
);
