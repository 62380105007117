/**
 * Wizard Package LOB form
 */
import * as React from "react";
import { inject, observer } from "mobx-react";
import { FlightTypeCodeMap } from "stores/wizard/state/flight/typings";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { ACTION_DONE, ACTION_TOGGLE, getTracker, TrackingUtils } from "utils/TrackingUtils";
import { FlightType, PackagesSubLobs } from "stores/wizard/state/typings";
import { ClassCodes } from "components/shared/PreferredClassInput/PreferredClassInput";
import { PreferredAirline } from "src/components/shared/PreferredAirlineInput/PreferredAirlineInput";
import { isVariantEnabled } from "components/utility/experiment";
import { getOffsetDatesByDatesOption } from "src/components/utility/OffsetDate/OffsetDateUtil";
import { locKeys } from "./l10n";
import { LobCombinations, PackageTab, WizardPackagePWAProps, WizardPackagePWAFlexModelResult } from "./typings";
import { WizardPackagePWAView } from "./views/WizardPackagePWAView";
import { WizardPackagePWAViewHotwire } from "./views/WizardPackagePWAViewHotwire";
import { WizardPackagePWAViews } from "./utils/WizardPackagePWAViews";
import { useInitializer } from "src/utils/hooks/useInitializer";

const PREFERRED_CLASS = "preferred-class.";
const PARTIAL_STAY = "hotel-partial-stay.";

const getComponentFromView = (view?: string) => {
  if (view === WizardPackagePWAViews.HOTWIRE) {
    return WizardPackagePWAViewHotwire;
  }

  return WizardPackagePWAView;
};

const WizardPackagePWAModule = (props: WizardPackagePWAProps) => {
  const { context, flexModuleModelStore, templateComponent, wizardState, analytics } = props;
  const { packageWizardState, thirdPartyPackageWizardState, globalWizardState, hotelWizardState } = wizardState;
  const {
    metadata,
    metadata: { id },
  } = templateComponent;
  const model = flexModuleModelStore.getModel(id) as WizardPackagePWAFlexModelResult | null;
  const hasMount = React.useRef(false);

  const updateSoftPackagesOnReflection = () => {
    const { FLIGHT_HOTEL_CAR, HOTEL_CAR, FLIGHT_HOTEL } = PackagesSubLobs;
    const isAddFlightEnabled = hotelWizardState.config.enableAddFlight;
    const isAddCarEnabled = hotelWizardState.config.enableAddCar;

    switch (packageWizardState.subLOBState) {
      case FLIGHT_HOTEL:
        hotelWizardState.toggleAddAFlightCheckbox({ stateToForce: isAddFlightEnabled });
        hotelWizardState.toggleAddACarCheckbox({ stateToForce: false });
        break;
      case FLIGHT_HOTEL_CAR:
        hotelWizardState.toggleAddAFlightCheckbox({ stateToForce: isAddFlightEnabled });
        hotelWizardState.toggleAddACarCheckbox({ stateToForce: isAddCarEnabled });
        break;
      case HOTEL_CAR:
        hotelWizardState.toggleAddAFlightCheckbox({ stateToForce: false });
        hotelWizardState.toggleAddACarCheckbox({ stateToForce: isAddCarEnabled });
        break;
      default:
        hotelWizardState.toggleAddAFlightCheckbox({ stateToForce: false });
        hotelWizardState.toggleAddACarCheckbox({ stateToForce: false });
    }
  };

  const setDestination = (context: ExtendedContextStore) => {
    const searchContext = context.searchContext;

    if (searchContext.lob === "package" && searchContext.location) {
      packageWizardState.updateDestination(searchContext.location.extendedName, searchContext.location.id);
    }
  };

  if (!model) {
    return null;
  }

  const subLobsfromFM: string[] = model.packageTypes;

  const getPackageSubLOBs = () => {
    const lobsFromFM = new Map<string, string[]>();
    const pkgSubLOBs = new Set<string>();
    const lobsAllowed: string[] = [];

    if (subLobsfromFM !== undefined) {
      if (subLobsfromFM.includes("flightshotelscars")) {
        pkgSubLOBs.add("flights");
        pkgSubLOBs.add("hotels");
        pkgSubLOBs.add("cars");
        lobsAllowed.push("fhc");
      }
      if (subLobsfromFM.includes("flightshotels")) {
        pkgSubLOBs.add("flights");
        pkgSubLOBs.add("hotels");
        lobsAllowed.push("fh");
      }
      if (subLobsfromFM.includes("hotelscars")) {
        pkgSubLOBs.add("hotels");
        pkgSubLOBs.add("cars");
        lobsAllowed.push("hc");
      }
      if (subLobsfromFM.includes("flightscars")) {
        pkgSubLOBs.add("flights");
        pkgSubLOBs.add("cars");
        lobsAllowed.push("fc");
      }
    } else {
      pkgSubLOBs.add("flights");
      pkgSubLOBs.add("hotels");
      pkgSubLOBs.add("cars");
      lobsAllowed.push("fhc", "fc", "fh", "hc");
    }

    lobsFromFM.set(
      "pills",
      Array.from(pkgSubLOBs).sort((s1, s2) => (s1 > s2 ? -1 : 1))
    );
    lobsFromFM.set("lobsAllowed", lobsAllowed);

    return lobsFromFM;
  };

  const packageSubLOBs: Map<string, string[]> = getPackageSubLOBs();

  const getDefaultPackageType = (): LobCombinations => {
    const defaultLob = PackagesSubLobs.FLIGHT_HOTEL;
    if (Object.values(packageSubLOBs.get("lobsAllowed")!).some((val) => val === defaultLob))
      return defaultLob as LobCombinations;
    return packageSubLOBs.get("lobsAllowed")!.values().next().value as LobCombinations;
  };

  const defaultPackageType: LobCombinations = getDefaultPackageType();

  useInitializer(() => {
    if (!model) return;

    packageWizardState.overrideConfig(() => {
      packageWizardState.config.elementId = id;
      packageWizardState.config.heading.fixedTitle = model.fixedLobTitle;
      packageWizardState.config.heading.lobSubtitleBreakPoint = model.lobSubtitleBreakPoint;
      packageWizardState.config.heading.hideLobTitle = model.hideLobTitle;
      packageWizardState.config.defaultTabEnabled = isVariantEnabled(context, "PWA_PACKAGE_WIZARD_3PP_DEFAULT_TAB");

      if (model.titleFromPageHeader) {
        packageWizardState.config.heading.differentiatedTitles = false;
      }

      const { includeSingleLOBsWithinPackages } = globalWizardState.config;

      if (includeSingleLOBsWithinPackages) {
        const multiLOBPackageWizardLabelKey = "wizard.packages.valuePropSubtitle.v2";
        packageWizardState.config.pillsLabelKey = multiLOBPackageWizardLabelKey;
      }

      packageWizardState.updateEssAdapterConfigPackageType(defaultPackageType);
    });

    wizardState.packageWizardState.localDateFormat = context.localDateFormat;

    const { defaultOffsetDates } = packageWizardState.config.date;
    const { startDateOffset, endDateOffset } = getOffsetDatesByDatesOption(model);

    if (defaultOffsetDates && startDateOffset !== undefined && endDateOffset !== undefined) {
      defaultOffsetDates.start = startDateOffset;
      defaultOffsetDates.end = endDateOffset;
      packageWizardState.updatePackageDatesFromConfig();
      packageWizardState.updatePartialDatesFromConfig();
    }

    packageWizardState.updateDateFromConfig();

    if (!packageWizardState.subLOBState) {
      packageWizardState.updateSubLOBState(defaultPackageType);
    }

    setDestination(context);

    packageWizardState.updateExperimentNewPackageVariant(context);

    packageWizardState.moduleName = metadata.name ? metadata.name : "";
  });

  React.useEffect(() => {
    if (hasMount.current) {
      updateSoftPackagesOnReflection();
    } else {
      const {
        context: { searchContext },
        wizardState: { packageWizardState },
      } = props;

      packageWizardState.updateDestinationOnRegionType(
        searchContext.location?.type,
        packageWizardState.location.destination.value
      );

      hasMount.current = true;
    }
  });

  const tracker: TrackingUtils = getTracker(
    packageWizardState.trackingEnabled,
    analytics,
    packageWizardState.moduleName
  );

  const onSelectPreferredClassCode = (classCode: ClassCodes) => {
    packageWizardState.setFlightClass(classCode);

    tracker.trackEvent(PREFERRED_CLASS + ACTION_DONE);
  };

  const onSelectPreferredAirline = (preferredAirline: PreferredAirline) => {
    packageWizardState.setFlightAirline(preferredAirline);

    tracker.trackEvent(PREFERRED_CLASS + ACTION_DONE);
  };

  const togglePartialStay = () => {
    packageWizardState.togglePartialStay();

    tracker.trackEvent(PARTIAL_STAY + ACTION_TOGGLE);
  };

  /* istanbul ignore next: never tested, function is mocked and test as part of other test component */
  const setFlightType = () => {
    const activeFlightType = globalWizardState.flightTypeCode;

    switch (activeFlightType) {
      case FlightType.ONE_WAY:
        packageWizardState.updateSubLOBFlightTypeState(FlightType.ONE_WAY);
        packageWizardState.updateOnewayFlag(true);

        return props.l10n.formatText(locKeys.flightOneWayText);
      case FlightType.MULTI_CITY:
        packageWizardState.updateSubLOBFlightTypeState(FlightType.MULTI_CITY);
        packageWizardState.updateOnewayFlag(false);

        return props.l10n.formatText(locKeys.flightMultiCityText);
      case FlightType.ROUND_TRIP:
      default:
        packageWizardState.updateSubLOBFlightTypeState(FlightType.ROUND_TRIP);
        packageWizardState.updateOnewayFlag(false);

        return props.l10n.formatText(locKeys.flightRoundTripText);
    }
  };

  const onSubLOBSelection = (pwaSubLOB: string) => () => {
    packageWizardState.updateSubLOBState(pwaSubLOB);
    updateSoftPackagesOnReflection();
  };

  const onFlightTypeUpdate = (pwaSubLOBFlightType: FlightType) => {
    packageWizardState.setFlightType(pwaSubLOBFlightType || FlightType.ROUND_TRIP);

    const packageType = packageWizardState.subLOBState || PackagesSubLobs.FLIGHT_HOTEL;
    const flightTypeCode = FlightTypeCodeMap[pwaSubLOBFlightType] || FlightTypeCodeMap.default;

    tracker.trackEvent(`HP.ASO.PACKAGE.${packageType.toUpperCase()}.${flightTypeCode}`);
  };

  const getDefaultTab = () => {
    let isAllInclusiveDestination;
    if (packageWizardState.config.defaultTabEnabled) {
      const packageWith3PP = model.include3PP ?? globalWizardState.config.packageWith3PP;
      isAllInclusiveDestination =
        packageWith3PP && model.countryCode && model.allInclusiveDefaultCountryCodes?.includes(model.countryCode);
    }

    const defaultTabConfigured = model.defaultPackageSubLOB ?? thirdPartyPackageWizardState.config.defaultPackageForm;

    return isAllInclusiveDestination ? PackageTab.AllInclusiveTab : defaultTabConfigured;
  };

  const getLocError = (isLink: boolean) => {
    const { config: packageConfig } = packageWizardState;

    if (!isLink) {
      return packageWizardState.numberOfErrors
        ? packageConfig.form.submitButton.locWarningsWithNumberToken!
        : packageConfig.form.submitButton.locWarningsToken!;
    }

    return packageWizardState.moreThanOneError()
      ? packageConfig.form.submitButton.locFirstWarningLinkToken
      : packageConfig.form.submitButton.locWarningLinkToken;
  };

  const setInputsRefs = (ref: React.RefObject<HTMLInputElement>, secondRef?: React.RefObject<HTMLInputElement>) => {
    packageWizardState.wizardInputsArray.push(ref);

    if (secondRef) {
      packageWizardState.wizardInputsArray.push(secondRef);
    }
  };

  const ViewComponent = getComponentFromView(model.view);

  return (
    <ViewComponent
      {...props}
      setFlightType={setFlightType}
      togglePartialStay={togglePartialStay}
      defaultPackageType={defaultPackageType}
      onSubLOBSelection={onSubLOBSelection}
      onFlightTypeUpdate={onFlightTypeUpdate}
      onSelectPreferredClassCode={onSelectPreferredClassCode}
      onSelectPreferredAirline={onSelectPreferredAirline}
      packageSubLOBs={packageSubLOBs}
      defaultTab={getDefaultTab()}
      setInputsRefs={setInputsRefs}
      getLocError={getLocError}
    />
  );
};

export const WizardPackagePWA = inject(
  "analytics",
  "wizardState",
  "flexModuleModelStore",
  "page"
)(observer(WizardPackagePWAModule));

export default WizardPackagePWA;
