import * as React from "react";

import { useHistory } from "react-router-dom";
import { ClientWizardStateStore } from "src/stores/wizard/ClientWizardStateStore";

import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { FlightTypeMenu } from "../../WizardFlightPWA/components/FlightTypeMenu";
import { UitkLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";
import { Travelers } from "src/components/shared/Travelers/Travelers";
import { ClassCodes, PreferredClassInput } from "src/components/shared/PreferredClassInput/PreferredClassInput";
import { NoRehydratationValues, TravelersTriggerTypes } from "src/components/shared/TravelersField/typings";
import { FlightType, PackagesSubLobs } from "src/stores/wizard/state/typings";
import {
  PreferredAirline,
  PreferredAirlineInput,
} from "src/components/shared/PreferredAirlineInput/PreferredAirlineInput";
import { CarTypeDropdown } from "../../WizardCarPWA/components/vendorOptions/CarTypeDropdown";

export type MenuFieldsProps = {
  currentSubLOB: string;
  wizardState: ClientWizardStateStore;
  noRehydratationValues?: NoRehydratationValues;
  displayNewPackageFlightType: boolean;
  onFlightTypeUpdate: (FlightType: string) => void;
  showOneway: boolean;
  onSelectPreferredClassCode: (classCode: ClassCodes) => void;
  onSelectPreferredAirline: (flightAirline: PreferredAirline) => void;
  activeFlightType: FlightType;
};

export const MenuFields: React.FC<MenuFieldsProps> = ({
  currentSubLOB,
  wizardState,
  noRehydratationValues,
  displayNewPackageFlightType,
  onFlightTypeUpdate,
  showOneway,
  onSelectPreferredClassCode,
  onSelectPreferredAirline,
  activeFlightType,
}) => {
  const { packageWizardState, globalWizardState, carWizardState } = wizardState;
  const { config: packageConfig } = packageWizardState;
  const { config: globalConfig } = globalWizardState;
  const { config: carConfig } = carWizardState;
  const displayBiasedWizard = globalConfig.displayBiasedWizard;
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);

  const travelersColSpan: UitkLayoutConditionalGridSpan = { small: 2, medium: 12 };
  const preferredClassColSpan: UitkLayoutConditionalGridSpan = { small: 2, medium: 12 };
  const isPackageContainingFlight = !packageWizardState.isPackageHotelCar();
  const isPackageContainingCar = !packageWizardState.isPackageFlightHotel();

  return (
    <>
      <UitkLayoutFlex space="six" alignItems="center" className="packageSelectors" wrap="wrap">
        {displayNewPackageFlightType && (
          <UitkLayoutFlexItem className="packageSelector">
            <FlightTypeMenu
              onFlightTypeUpdate={onFlightTypeUpdate}
              activeFlightType={activeFlightType}
              showOneway={showOneway}
            />
          </UitkLayoutFlexItem>
        )}

        {currentSubLOB === PackagesSubLobs.FLIGHT_HOTEL && (
          <UitkLayoutFlexItem className="packageSelector">
            <Travelers
              tripLobType={queryParams.get("pwaSubLOB") || undefined}
              lobState={packageWizardState}
              colSpan={travelersColSpan}
              noRehydratationValues={noRehydratationValues}
              triggerType={TravelersTriggerTypes.LINK_BUTTON}
            />
          </UitkLayoutFlexItem>
        )}

        {currentSubLOB === PackagesSubLobs.FLIGHT_CAR && (
          <UitkLayoutFlexItem className="packageSelector">
            <Travelers
              tripLobType={queryParams.get("pwaSubLOB") || undefined}
              lobState={packageWizardState}
              colSpan={travelersColSpan}
              noRehydratationValues={{
                numOfTravelers: 1,
                name: "adultsPerRoom[1]",
              }}
              config={packageConfig.subLOB.flightCar.travelers}
              triggerType={TravelersTriggerTypes.LINK_BUTTON}
            />
          </UitkLayoutFlexItem>
        )}

        {currentSubLOB === PackagesSubLobs.FLIGHT_HOTEL_CAR && (
          <UitkLayoutFlexItem className="packageSelector">
            <Travelers
              tripLobType={queryParams.get("pwaSubLOB") || undefined}
              lobState={packageWizardState}
              colSpan={travelersColSpan}
              noRehydratationValues={noRehydratationValues}
              triggerType={TravelersTriggerTypes.LINK_BUTTON}
            />
          </UitkLayoutFlexItem>
        )}

        {currentSubLOB === PackagesSubLobs.HOTEL_CAR && (
          <UitkLayoutFlexItem className="packageSelector">
            <Travelers
              tripLobType={queryParams.get("pwaSubLOB") || undefined}
              lobState={packageWizardState}
              config={packageConfig.subLOB.hotelCar.travelers}
              colSpan={travelersColSpan}
              noRehydratationValues={noRehydratationValues}
              triggerType={TravelersTriggerTypes.LINK_BUTTON}
            />
          </UitkLayoutFlexItem>
        )}

        {packageConfig.showAllSubLOB && isPackageContainingFlight && (
          <>
            <UitkLayoutFlexItem className="packageSelector">
              <PreferredClassInput
                colSpan={preferredClassColSpan}
                onSelectPreferredClassCode={onSelectPreferredClassCode}
                wizardState={wizardState}
              />
            </UitkLayoutFlexItem>
            <UitkLayoutFlexItem className="packageSelector">
              <PreferredAirlineInput onSelectPreferredAirline={onSelectPreferredAirline} wizardState={wizardState} />
            </UitkLayoutFlexItem>
          </>
        )}

        {displayBiasedWizard && isPackageContainingCar && !carConfig.isBiasedWizardEmpty && !carConfig.carType && (
          <CarTypeDropdown carWizardState={carWizardState} />
        )}
      </UitkLayoutFlex>
    </>
  );
};
