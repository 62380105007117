import * as React from "react";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { ILOBCheckbox, ILOBMap, TypeMenuProps } from "../typings";
import { UitkPill } from "@egds/react-core/pill";
import { FlightType } from "stores/wizard/state/typings";
import { UitkSpacing } from "@egds/react-core/spacing";
import { useLocalization } from "@shared-ui/localization-context";
import { PackageWizardState } from "src/stores/wizard/state";

interface TypeMenuPillsProps extends TypeMenuProps {
  showMulticity: boolean;
  showOneway: boolean;
  setFlightType: () => string;
  onFlightTypeUpdate: (flightType: FlightType) => void;
  showEnhancedLabels: boolean;
  lobState: PackageWizardState;
}

export const TypeMenuPills = ({
  handleMenuChange,
  lobsMap,
  className,
  useLink,
  showEnhancedLabels,
  lobKeys,
  lobState,
}: TypeMenuPillsProps) => {
  const { formatText } = useLocalization();

  const renderPill = (key: string, { checked, ref, id, label, enhancedLabel }: ILOBCheckbox) => {
    const overridenLabel = checked && showEnhancedLabels ? enhancedLabel : label;

    const onChangePill = () => {
      handleMenuChange(key, ref, id);
    };

    return (
      <UitkPill
        id={!useLink ? `package-pills-${key}` : ""}
        checked={checked}
        active={checked}
        onChange={onChangePill}
        children={overridenLabel}
      />
    );
  };

  const checkLOBAddedExperimentLabels = () => {
    lobsMap.cars.label = lobsMap.cars.enhancedVariantLabel;
    lobsMap.hotels.label = lobsMap.hotels.enhancedVariantLabel;
    lobsMap.flights.label = lobsMap.flights.enhancedVariantLabel;
  };

  const pillsToLoad = () => {
    if (showEnhancedLabels) {
      checkLOBAddedExperimentLabels();
    }

    return lobKeys.map((lobKey) => {
      const lobCheckbox: ILOBCheckbox = lobsMap[lobKey as keyof ILOBMap];
      const pillToLoad = renderPill(lobKey, lobCheckbox);
      const adaptiveMenuLinkPill = (
        <UitkSpacing margin={{ inlinestart: "one", inlineend: "one" }}>
          <button>{pillToLoad}</button>
        </UitkSpacing>
      );

      const typePillToLoad = useLink ? adaptiveMenuLinkPill : pillToLoad;

      return <UitkLayoutFlexItem key={lobKey}>{typePillToLoad}</UitkLayoutFlexItem>;
    });
  };

  const pillsLabelKey = lobState.config.pillsLabelKey;

  return (
    <UitkLayoutFlexItem className={className}>
      <div role="group" aria-label={formatText(pillsLabelKey)} data-testid="pills-group">
        <UitkLayoutFlex dataTestId="type-menu-pills">{pillsToLoad()}</UitkLayoutFlex>
      </div>
    </UitkLayoutFlexItem>
  );
};
