import * as React from "react";
import { UitkHeading, UitkHeadingProps, UitkText } from "@egds/react-core/text";
import { LobTitleProps } from "./typings";
import { useLocalization } from "@shared-ui/localization-context";
import { Viewport, ViewMedium, ViewSmall } from "@shared-ui/viewport-context";
import { UitkSpacing } from "@egds/react-core/spacing";

export const PackageLobTitle: React.FunctionComponent<LobTitleProps> = ({ lobState, pageHeading, pageSubHeading }) => {
  const { formatText } = useLocalization();
  const headingConfig: UitkHeadingProps = { tag: "h1", size: 3 };
  const heading = <UitkHeading {...headingConfig}>{pageHeading}</UitkHeading>;

  if (lobState.config.heading.hideLobTitle) {
    return null;
  }

  if (lobState.config.heading.differentiatedTitles) {
    return (
      <Viewport>
        <ViewMedium>
          <UitkText weight="medium" theme="emphasis" size={700}>
            {formatText(lobState.config.heading.locTitleDesktopTextToken)}
          </UitkText>
        </ViewMedium>
        <ViewSmall>{heading}</ViewSmall>
      </Viewport>
    );
  }

  return (
    <UitkSpacing padding={!lobState.config.showAllSubLOB ? { blockend: "two" } : { blockend: "unset" }}>
      <div>
        <UitkHeading {...headingConfig}>{pageHeading}</UitkHeading>
        {pageSubHeading && (
          <UitkText spacing="one" size={300}>
            {pageSubHeading}
          </UitkText>
        )}
      </div>
    </UitkSpacing>
  );
};
