import * as React from "react";

import { useLocalization } from "@shared-ui/localization-context";
import { UitkEmptyState } from "@egds/react-core/empty-state";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { UitkHeading, UitkText } from "@egds/react-core/text";

import { PackageWizardState } from "stores/wizard/state";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";

export interface PackageErrorStateProps {
  lobsMissing?: number;
  packageWizardState: PackageWizardState;
}

export const PackageErrorStateHotwire: React.FunctionComponent<PackageErrorStateProps> = ({ packageWizardState }) => {
  const { formatText } = useLocalization();

  const heading = formatText("wizard.package.invalid.header.selectTwoOrMore.text");
  const body = formatText("wizard.package.invalid.body.toStartBuilding.text");
  const { updateInvalidFormsState } = packageWizardState;

  updateInvalidFormsState();

  return (
    <UitkSpacing margin={{ blockend: "four" }}>
      <UitkLayoutFlex justifyContent="center" className="error">
        <UitkEmptyState>
          <UitkIcon name="search" size={UitkIconSize.LARGE} />
          <UitkHeading tag="h2" size={7}>
            {heading}
          </UitkHeading>
          <UitkText>{body}</UitkText>
        </UitkEmptyState>
      </UitkLayoutFlex>
    </UitkSpacing>
  );
};
