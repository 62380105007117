import * as React from "react";
import { observer } from "mobx-react";
import {
  WizardPackagePWAViewProps,
  WizardPackagePWAFlexModelResult,
  LobCombinations,
  PackageHiddenSearchDataProps,
  PackageLegInformation,
} from "../typings";
import { FlightHotel } from "../components/FlightHotel";
import { FlightHotelCar } from "../components/FlightHotelCar";
import { FlightCar } from "../components/FlightCar";
import { HotelCar } from "../components/HotelCar";
import { PackageHiddenSearchData } from "../components/PackageHiddenSearchData";
import { SubmitRow } from "src/components/shared/StorefrontWizard/SubmitRow/SubmitRow";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import {
  UitkLayoutGrid,
  UitkLayoutConditionalGridSpan,
  UitkLayoutConditionalGridTrack,
} from "@egds/react-core/layout-grid";
import { UitkSpacing, UitkSpacingProps } from "@egds/react-core/spacing";
import { PackageErrorState } from "./PackageErrorState";
import { LobErrorSummary } from "src/components/shared/LobErrorSummary/LobErrorSummary";
import { MenuFields } from "./MenuFields";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import { ValuePropSubtitle } from "./ValuePropSubtitle";
import { PackagesStandaloneWizardModulesContext } from "src/components/flexComponents/PageLevelProviders/WizardContextProvider/PackagesStandaloneWizardModulesContext";
import { withTypeMenu } from "./PackageTypeMenu";
import { TypeMenuPills } from "./TypeMenuPills";
import { withLocalization } from "bernie-l10n";
import { MultiLOBPackageWizardContext } from "src/components/flexComponents/PageLevelProviders/WizardContextProvider/MultiLOBPackageWizardContext";
import { PackagesSubLobs } from "src/stores/wizard/state/typings";
import { VendorCodes } from "../../WizardCarPWA/components/vendorOptions/VendorCodes";
import { Action } from "src/components/utility/analytics/FlexAnalyticsUtils";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { PackageWizardState } from "stores/wizard/state";
import { PackageConfig } from "stores/wizard/config";
import { RegionChildrenList } from "src/utils/RegionUtils";
import { useLocalization } from "@shared-ui/localization-context";

const getLegsInformation = (
  packageWizardState: PackageWizardState,
  packageConfig: PackageConfig,
  formatDate: (d: Date, args: any) => string
): PackageLegInformation => {
  const rawFormatForSubmission = packageConfig.date.ISOSubmissionSupport
    ? packageConfig.date.format
    : packageWizardState.localDateFormat;

  return {
    originTla: packageWizardState.location.origin.metaData.ttla,
    originId: packageWizardState.location.origin.metaData.regionId,
    destinationTla: packageWizardState.location.destination.metaData.ttla,
    destinationId: packageWizardState.location.destination.metaData.regionId,
    startDate: formatDate(packageWizardState.date.start, { raw: rawFormatForSubmission }),
    endDate: formatDate(packageWizardState.date.end, { raw: rawFormatForSubmission }),
  };
};

const getFlightInLegURLFragment = (
  packageWizardState: PackageWizardState,
  packageConfig: PackageConfig,
  formatDate: (d: Date, args: any) => string
): string => {
  const legsInfo: PackageLegInformation = getLegsInformation(packageWizardState, packageConfig, formatDate);

  return `orig:${legsInfo.destinationTla};origId:${legsInfo.destinationId};dest:${legsInfo.originTla};destId:${legsInfo.originId};departure:${legsInfo.endDate}`;
};

const getFlightOutLegURLFragment = (
  packageWizardState: PackageWizardState,
  packageConfig: PackageConfig,
  formatDate: (d: Date, args: any) => string
): string => {
  const legsInfo: PackageLegInformation = getLegsInformation(packageWizardState, packageConfig, formatDate);

  return `orig:${legsInfo.originTla};origId:${legsInfo.originId};dest:${legsInfo.destinationTla};destId:${legsInfo.destinationId};departure:${legsInfo.startDate}`;
};

/**
 * This implementation is exclusive for packages.
 * Do not repeat somewhere else.
 */
const TypeMenuWithPills = withLocalization(withTypeMenu(TypeMenuPills));

export const PackageForm: React.FunctionComponent<WizardPackagePWAViewProps> = observer((props) => {
  const { context, wizardState, defaultPackageType, templateComponent, flexModuleModelStore, page } = props;
  const {
    packageWizardState,
    packageWizardState: { config: packageConfig },
    globalWizardState,
    globalWizardState: { config: globalConfig },
    carWizardState,
    carWizardState: { config: carConfig },
    hotelWizardState: { config: hotelConfig },
  } = wizardState;

  const { formatDate } = useLocalization();

  const halfWizard = globalConfig?.halfWizard;
  const mainGridColumns: UitkLayoutConditionalGridTrack = { small: 2, medium: 12, large: 12 };
  const couponColSpan: UitkLayoutConditionalGridSpan = { small: 2, medium: halfWizard ? 6 : 4 };

  const packageHiddenSearchData: PackageHiddenSearchDataProps = {
    pwaSubLOB: packageWizardState.subLOBState,
    leg1: getFlightOutLegURLFragment(packageWizardState, packageConfig, formatDate) ?? "",
    leg2: packageWizardState.isRoundtripFlight()
      ? getFlightInLegURLFragment(packageWizardState, packageConfig, formatDate)
      : "",
    flightClass: packageWizardState.flightClassCode,
    flightAirline: packageWizardState.flightAirline,
    config: packageConfig,
    packageWizardState,
    hotelConfig,
  };

  const isNewPackageOnewayFlightType =
    packageWizardState.isDesktop && packageWizardState.experimentNewPackageOnewayFlightType.variant1;
  const displayNewPackageFlightType =
    packageWizardState.subLOBState === PackagesSubLobs.FLIGHT_HOTEL && isNewPackageOnewayFlightType;

  const [lobsMissing, setLobsMissing] = React.useState(0);

  /**
   * Values used if no rehydratation(JS disabled)
   */
  const { noRehydratationValues } = packageConfig.travelers;
  const {
    isFlightCarFormValid,
    isFlightHotelDefaultFormValid,
    isFlightHotelCarFormValid,
    isFlightHotelMultiFormValid,
    isFlightHotelOneWayFormValid,
    isHotelCarFormValid,
    numberOfErrors,
    moreThanOneError,
    errorInputRef,
    subLOBState,
    flightTypeCode,
  } = packageWizardState;

  const currentSubLOB = subLOBState || defaultPackageType;
  const errorSummaryGridColumns: UitkLayoutConditionalGridSpan = { small: 2, medium: 12 };

  const invalidPackageCombination = !Object.values(props.packageSubLOBs.get("lobsAllowed")!).some(
    (val) => val === currentSubLOB
  );
  const selectedLobs = !Object.values(PackagesSubLobs).some((val) => val === currentSubLOB);

  const formHasNoErrors =
    isFlightCarFormValid &&
    isFlightHotelCarFormValid &&
    isFlightHotelDefaultFormValid &&
    isFlightHotelMultiFormValid &&
    isFlightHotelOneWayFormValid &&
    isHotelCarFormValid;

  let lobsMissingErrorState = selectedLobs ? 2 : 0;
  lobsMissingErrorState = invalidPackageCombination && !selectedLobs ? 99 : lobsMissingErrorState;
  React.useEffect(() => setLobsMissing(lobsMissingErrorState), []);

  const { metadata } = templateComponent;
  const { id } = metadata;
  const model = flexModuleModelStore.getModel(id) as WizardPackagePWAFlexModelResult | null;
  if (model === null) {
    return null;
  }

  const showSubHeadingFromFlex = model.includePageSubHeading;
  const standaloneSubLOBS = [PackagesSubLobs.HOTELS, PackagesSubLobs.FLIGHTS, PackagesSubLobs.CARS];

  const displayVendorCodes =
    (currentSubLOB === PackagesSubLobs.HOTEL_CAR ||
      currentSubLOB === PackagesSubLobs.FLIGHT_CAR ||
      currentSubLOB === PackagesSubLobs.FLIGHT_HOTEL_CAR) &&
    !carConfig.isBiasedWizardEmpty;

  const pageName = page ? page.pageName : "unknown";
  const moduleName = halfWizard ? "HalfBiasedWizard" : "FullBiasedWizard";
  const linkName = "PackageSearch";
  const sectionName = "SQM";
  const rfrrid = `${pageName}.${moduleName}.${linkName}.${sectionName}`;
  const extraTracking: any = {
    schemaName: "referrer",
    messageContent: {
      referrerId: rfrrid,
      eventType: Action.CLICK,
    },
  };

  return (
    <PackagesStandaloneWizardModulesContext.Consumer>
      {(value) => {
        const { templateComponents: singleLOBWizards = [], blossomComponent } = value;
        const hasSingleWizards = singleLOBWizards.length > 0;
        const isSingleSubLOB = standaloneSubLOBS.includes(currentSubLOB as PackagesSubLobs);
        const displayStandaloneWizard = hasSingleWizards && isSingleSubLOB;
        const wizardHotel = singleLOBWizards.filter(({ metadata }) => metadata?.id === "wizard-hotel-pwa-v2-1");
        const wizardFlight = singleLOBWizards.filter(({ metadata }) => metadata?.id === "wizard-flight-pwa-1");
        const wizardCar = singleLOBWizards.filter(({ metadata }) => metadata?.id === "wizard-car-pwa-1");

        const pillsCopyMargin: UitkSpacingProps["margin"] = invalidPackageCombination
          ? { blockstart: "four", blockend: "one" }
          : undefined;

        const pillsGridMargin: UitkSpacingProps["margin"] = !displayStandaloneWizard
          ? { small: { blockend: "three" }, large: { blockend: "six" } }
          : { blockend: "three" };
        const pillsGridJustifyContent = packageConfig.showAllSubLOB ? "space-between" : "end";
        const packageFormMargin: UitkSpacingProps["margin"] = { small: { blockstart: "three" } };
        const fmId = getFmId(templateComponent);
        const fmTitleId = templateComponent.config.fmTitleId;
        const disableLobSelection =
          globalWizardState.config.displayBiasedWizard && packageWizardState.config.disableToggleOnPackage;

        return (
          <>
            <form
              noValidate
              action={packageConfig.form.action}
              autoComplete="off"
              className="WizardPackagePWA"
              data-testid="wizard"
              onSubmit={packageWizardState.submit}
              id={packageConfig.elementId}
              data-fm={fmId}
              data-fm-title-id={fmTitleId}
            >
              <UitkSpacing margin={pillsGridMargin}>
                <UitkLayoutFlex justifyContent={pillsGridJustifyContent} alignItems="end">
                  {packageConfig.showAllSubLOB && (
                    <UitkLayoutFlexItem>
                      {!disableLobSelection && (
                        <div>
                          {packageConfig.showAllSubLOB && !showSubHeadingFromFlex && (
                            <ValuePropSubtitle config={packageConfig} margin={pillsCopyMargin} />
                          )}
                          <UitkSpacing
                            padding={currentSubLOB !== "none" && isSingleSubLOB ? { blockstart: "two" } : undefined}
                          >
                            <TypeMenuWithPills
                              context={context}
                              lobState={packageWizardState}
                              onSubLOBSelection={props.onSubLOBSelection}
                              defaultPackageType={
                                (packageWizardState.subLOBState as LobCombinations) || props.defaultPackageType
                              }
                              updateLobsMissing={setLobsMissing}
                              minimumLobsNeeded={packageConfig.minimumLOBsNeeded}
                              packageSubLOBs={props.packageSubLOBs}
                              currentSubLOB={currentSubLOB}
                              showOneway={isNewPackageOnewayFlightType}
                              showMulticity={false}
                              onFlightTypeUpdate={props.onFlightTypeUpdate}
                              setFlightType={props.setFlightType}
                              globalWizardState={globalWizardState}
                            />
                          </UitkSpacing>
                        </div>
                      )}
                    </UitkLayoutFlexItem>
                  )}
                  {!halfWizard && !invalidPackageCombination && !displayStandaloneWizard && (
                    <Viewport>
                      <ViewMedium>
                        <UitkLayoutFlexItem>
                          <div>
                            <MenuFields
                              currentSubLOB={currentSubLOB}
                              wizardState={props.wizardState}
                              noRehydratationValues={noRehydratationValues}
                              displayNewPackageFlightType={displayNewPackageFlightType}
                              onFlightTypeUpdate={props.onFlightTypeUpdate}
                              activeFlightType={flightTypeCode}
                              showOneway={isNewPackageOnewayFlightType}
                              onSelectPreferredClassCode={props.onSelectPreferredClassCode}
                              onSelectPreferredAirline={props.onSelectPreferredAirline}
                            />
                          </div>
                        </UitkLayoutFlexItem>
                      </ViewMedium>
                    </Viewport>
                  )}
                </UitkLayoutFlex>
              </UitkSpacing>
              {halfWizard && !invalidPackageCombination && !displayStandaloneWizard && (
                <Viewport>
                  <ViewMedium>
                    <MenuFields
                      currentSubLOB={currentSubLOB}
                      wizardState={props.wizardState}
                      noRehydratationValues={noRehydratationValues}
                      displayNewPackageFlightType={displayNewPackageFlightType}
                      onFlightTypeUpdate={props.onFlightTypeUpdate}
                      activeFlightType={flightTypeCode}
                      showOneway={isNewPackageOnewayFlightType}
                      onSelectPreferredClassCode={props.onSelectPreferredClassCode}
                      onSelectPreferredAirline={props.onSelectPreferredAirline}
                    />
                  </ViewMedium>
                </Viewport>
              )}
              {!invalidPackageCombination && !displayStandaloneWizard && (
                <Viewport>
                  <ViewSmall>
                    <MenuFields
                      currentSubLOB={currentSubLOB}
                      wizardState={props.wizardState}
                      noRehydratationValues={noRehydratationValues}
                      displayNewPackageFlightType={displayNewPackageFlightType}
                      onFlightTypeUpdate={props.onFlightTypeUpdate}
                      activeFlightType={flightTypeCode}
                      showOneway={isNewPackageOnewayFlightType}
                      onSelectPreferredClassCode={props.onSelectPreferredClassCode}
                      onSelectPreferredAirline={props.onSelectPreferredAirline}
                    />
                  </ViewSmall>
                  <ViewMedium />
                </Viewport>
              )}

              {!formHasNoErrors && !displayStandaloneWizard && (
                <UitkSpacing padding={{ blockstart: "three", blockend: "three" }}>
                  <UitkLayoutGrid columns={mainGridColumns} space="three">
                    <LobErrorSummary
                      locHeadingToken={props.getLocError!(false)!}
                      locHeadingArgs={moreThanOneError() && [numberOfErrors]}
                      locLinkToken={props.getLocError!(true)}
                      linkClickFocusId="id-roundtrip-error"
                      inputErrorRef={errorInputRef}
                      colSpan={errorSummaryGridColumns}
                      setInputsRefs={props.setInputsRefs}
                    />
                  </UitkLayoutGrid>
                </UitkSpacing>
              )}
              <UitkSpacing margin={packageFormMargin}>
                <UitkLayoutGrid columns={mainGridColumns} space="three">
                  {!invalidPackageCombination && currentSubLOB === PackagesSubLobs.FLIGHT_HOTEL && (
                    <FlightHotel
                      {...props}
                      packageType={PackagesSubLobs.FLIGHT_HOTEL}
                      packageConfig={packageConfig}
                      packageWizardState={packageWizardState}
                      togglePartialStay={props.togglePartialStay}
                      setFlightType={props.setFlightType}
                      setInputsRefs={props.setInputsRefs}
                      globalConfig={globalConfig}
                    />
                  )}

                  {!invalidPackageCombination && currentSubLOB === PackagesSubLobs.FLIGHT_HOTEL_CAR && (
                    <FlightHotelCar
                      {...props}
                      packageType={PackagesSubLobs.FLIGHT_HOTEL_CAR}
                      packageConfig={packageConfig}
                      packageWizardState={packageWizardState}
                      togglePartialStay={props.togglePartialStay}
                      setInputsRefs={props.setInputsRefs}
                      globalConfig={globalConfig}
                    />
                  )}
                  {!invalidPackageCombination && currentSubLOB === PackagesSubLobs.FLIGHT_CAR && (
                    <FlightCar
                      {...props}
                      packageType={PackagesSubLobs.FLIGHT_CAR}
                      packageConfig={packageConfig}
                      packageWizardState={packageWizardState}
                      togglePartialStay={props.togglePartialStay}
                      setInputsRefs={props.setInputsRefs}
                      globalConfig={globalConfig}
                    />
                  )}
                  {!invalidPackageCombination && currentSubLOB === PackagesSubLobs.HOTEL_CAR && (
                    <HotelCar
                      {...props}
                      packageType={PackagesSubLobs.HOTEL_CAR}
                      packageConfig={packageConfig}
                      packageWizardState={packageWizardState}
                      togglePartialStay={props.togglePartialStay}
                      setInputsRefs={props.setInputsRefs}
                      globalConfig={globalConfig}
                      carWizardState={carWizardState}
                    />
                  )}
                  <PackageHiddenSearchData {...packageHiddenSearchData} />
                </UitkLayoutGrid>
              </UitkSpacing>
              {invalidPackageCombination && !displayStandaloneWizard && (
                <PackageErrorState
                  context={context}
                  lobState={packageWizardState}
                  lobsMissing={lobsMissing}
                  packageWizardState={packageWizardState}
                  globalWizardState={globalWizardState}
                />
              )}

              {!invalidPackageCombination && !displayStandaloneWizard && displayVendorCodes && (
                <VendorCodes
                  carWizardState={carWizardState}
                  mainColSpan={mainGridColumns}
                  colSpan={couponColSpan}
                  globalConfig={globalConfig}
                />
              )}
              {!invalidPackageCombination && !displayStandaloneWizard && (
                <SubmitRow
                  lobState={packageWizardState}
                  rfrr="SearchClick_Package"
                  extraUISTracking={extraTracking}
                  globalConfig={globalConfig}
                />
              )}
            </form>

            <MultiLOBPackageWizardContext.Provider value={{ isInsideMultiLOBPackageWizard: true }}>
              {currentSubLOB === PackagesSubLobs.HOTELS && blossomComponent && (
                <RegionChildrenList templateComponents={wizardHotel} blossomComponent={blossomComponent} />
              )}

              {currentSubLOB === PackagesSubLobs.FLIGHTS && blossomComponent && (
                <RegionChildrenList templateComponents={wizardFlight} blossomComponent={blossomComponent} />
              )}

              {currentSubLOB === PackagesSubLobs.CARS && blossomComponent && (
                <RegionChildrenList templateComponents={wizardCar} blossomComponent={blossomComponent} />
              )}
            </MultiLOBPackageWizardContext.Provider>
          </>
        );
      }}
    </PackagesStandaloneWizardModulesContext.Consumer>
  );
});
