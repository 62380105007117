import * as React from "react";
import { observer } from "mobx-react";

import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { withLocalization } from "bernie-l10n";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkSpacing, UitkSpacingProps } from "@egds/react-core/spacing";
import {
  UitkLayoutConditionalGridTrack,
  UitkLayoutConditionalGridSpan,
  UitkLayoutGrid,
} from "@egds/react-core/layout-grid";

import { useMultiLOBWizardRegion } from "components/flexComponents/WizardPWARegions/WizardRegionHotwire/WizardRegionContextProvider/MultiLOBWizardRegionContext";
import { LobErrorSummary } from "components/shared/LobErrorSummary/LobErrorSummary";
import { PackagesSubLobs } from "stores/wizard/state/typings";
import { PackageErrorStateHotwire } from "../components/hotwire/PackageErrorStateHotwire";
import { withTypeMenu } from "../components/PackageTypeMenu";
import { TypeMenuPillsHotwire } from "../components/hotwire/TypeMenuPillsHotwire";
import { FlightHotelHotwire } from "../components/hotwire/FlightHotelHotwire";
import { FlightCarHotwire } from "../components/hotwire/FlightCarHotwire";
import { HotelCarHotwire } from "../components/hotwire/HotelCarHotwire";
import { FlightHotelCarHotwire } from "../components/hotwire/FlightHotelCarHotwire";
import { ValuePropSubtitleHotwire } from "../components/hotwire/ValuePropSubtitleHotwire";
import { PackageHiddenSearchDataHotwire } from "../components/hotwire/PackageHiddenSearchDataHotwire";
import { useHotwirePackageOverrides } from "../utils/hotwirePackageOverrides";
import { LobCombinations, PackageHiddenSearchDataHotwireProps, WizardPackagePWAViewProps } from "../typings";
import { getFmId } from "src/stores/ExperienceTemplateStore";

/**
 * This implementation is exclusive por packages.
 * Do not repeat somewhere else.
 */
const TypeMenuHotwire = withLocalization(withTypeMenu(TypeMenuPillsHotwire));

export const WizardPackagePWAViewHotwire: React.FunctionComponent<WizardPackagePWAViewProps> = observer((props) => {
  const { context, wizardState, defaultPackageType, templateComponent } = props;
  const {
    packageWizardState,
    globalWizardState,
    globalWizardState: { config: globalConfig },
    carWizardState,
  } = wizardState;
  useHotwirePackageOverrides(packageWizardState);
  const { config: packageConfig } = packageWizardState;
  const mainGridColumns: UitkLayoutConditionalGridTrack = { small: 2, medium: 12, large: 12 };

  const packageHiddenSearchData: PackageHiddenSearchDataHotwireProps = {
    pwaSubLOB: packageWizardState.subLOBState,
    config: packageConfig,
    packageWizardState,
  };

  const [lobsMissing, setLobsMissing] = React.useState(0);

  /**
   * Values used if no rehydratation(JS disabled)
   */
  const {
    isFlightCarFormValid,
    isFlightHotelDefaultFormValid,
    isFlightHotelCarFormValid,
    isFlightHotelMultiFormValid,
    isFlightHotelOneWayFormValid,
    isHotelCarFormValid,
    numberOfErrors,
    moreThanOneError,
    errorInputRef,
    subLOBState,
  } = packageWizardState;

  const currentSubLOB = subLOBState || defaultPackageType;
  const errorSummaryGridColumns: UitkLayoutConditionalGridSpan = { small: 2, medium: 12 };

  const invalidPackageCombination = !Object.values(props.packageSubLOBs.get("lobsAllowed")!).some(
    (val) => val === currentSubLOB
  );
  const selectedLobs = !Object.values(PackagesSubLobs).some((val) => val === currentSubLOB);

  const formHasNoErrors =
    isFlightCarFormValid &&
    isFlightHotelCarFormValid &&
    isFlightHotelDefaultFormValid &&
    isFlightHotelMultiFormValid &&
    isFlightHotelOneWayFormValid &&
    isHotelCarFormValid;

  const currentMisingLobs = selectedLobs ? 2 : 0;
  const lobsMissingErrorState = invalidPackageCombination && !selectedLobs ? 99 : currentMisingLobs;
  React.useEffect(() => setLobsMissing(lobsMissingErrorState), []);
  const pillsCopyMargin: UitkSpacingProps["margin"] = { blockstart: "three", blockend: "four" };

  const pillsGridMargin: UitkSpacingProps["margin"] = { blockend: "three" };
  const pillsGridJustifyContent = packageConfig.showAllSubLOB ? "space-between" : "end";
  const { isMultiLOB } = useMultiLOBWizardRegion();
  const fmId = getFmId(templateComponent);
  const fmTitleId = templateComponent.config.fmTitleId;

  return (
    <form
      noValidate
      action={packageConfig.form.action}
      autoComplete="off"
      className="WizardPackagePWAHotwire"
      data-testid="wizard"
      onSubmit={packageWizardState.submit}
      id={packageConfig.elementId}
      data-fm={fmId}
      data-fm-title-id={fmTitleId}
    >
      <UitkSpacing margin={pillsGridMargin}>
        <UitkLayoutFlex justifyContent={pillsGridJustifyContent} alignItems="end">
          {packageConfig.showAllSubLOB && (
            <UitkLayoutFlexItem>
              <div>
                <div className={isMultiLOB ? "" : "subtitle"}>
                  <Viewport>
                    <ViewSmall>
                      <ValuePropSubtitleHotwire config={packageConfig} margin={pillsCopyMargin} />
                    </ViewSmall>
                    <ViewMedium>
                      <ValuePropSubtitleHotwire
                        config={packageConfig}
                        margin={pillsCopyMargin}
                        whiteText={!isMultiLOB}
                      />
                    </ViewMedium>
                  </Viewport>
                </div>
                <TypeMenuHotwire
                  context={context}
                  lobState={packageWizardState}
                  onSubLOBSelection={props.onSubLOBSelection}
                  defaultPackageType={(packageWizardState.subLOBState as LobCombinations) || props.defaultPackageType}
                  updateLobsMissing={setLobsMissing}
                  minimumLobsNeeded={packageConfig.minimumLOBsNeeded}
                  packageSubLOBs={props.packageSubLOBs}
                  currentSubLOB={currentSubLOB}
                  showOneway={false}
                  showMulticity={false}
                  onFlightTypeUpdate={props.onFlightTypeUpdate}
                  setFlightType={props.setFlightType}
                  globalWizardState={globalWizardState}
                />
              </div>
            </UitkLayoutFlexItem>
          )}
        </UitkLayoutFlex>
      </UitkSpacing>
      {!formHasNoErrors && (
        <UitkSpacing padding={{ blockstart: "three", blockend: "three" }}>
          <UitkLayoutGrid columns={mainGridColumns} space="three">
            <LobErrorSummary
              locHeadingToken={props.getLocError!(false)!}
              locHeadingArgs={moreThanOneError() && [numberOfErrors]}
              locLinkToken={props.getLocError!(true)}
              linkClickFocusId="id-roundtrip-error"
              inputErrorRef={errorInputRef}
              colSpan={errorSummaryGridColumns}
              setInputsRefs={props.setInputsRefs}
            />
          </UitkLayoutGrid>
        </UitkSpacing>
      )}

      <UitkLayoutGrid columns={mainGridColumns} space="three">
        {currentSubLOB === PackagesSubLobs.FLIGHT_HOTEL && (
          <FlightHotelHotwire
            {...props}
            currentSubLOB={currentSubLOB}
            packageType={PackagesSubLobs.FLIGHT_HOTEL}
            packageConfig={packageConfig}
            packageWizardState={packageWizardState}
            togglePartialStay={props.togglePartialStay}
            setFlightType={props.setFlightType}
            setInputsRefs={props.setInputsRefs}
            globalConfig={globalConfig}
          />
        )}

        {!invalidPackageCombination && currentSubLOB === PackagesSubLobs.FLIGHT_HOTEL_CAR && (
          <FlightHotelCarHotwire
            {...props}
            currentSubLOB={currentSubLOB}
            packageType={PackagesSubLobs.FLIGHT_HOTEL_CAR}
            packageConfig={packageConfig}
            packageWizardState={packageWizardState}
            togglePartialStay={props.togglePartialStay}
            setInputsRefs={props.setInputsRefs}
            globalConfig={globalConfig}
          />
        )}
        {!invalidPackageCombination && currentSubLOB === PackagesSubLobs.FLIGHT_CAR && (
          <FlightCarHotwire
            {...props}
            currentSubLOB={currentSubLOB}
            packageType={PackagesSubLobs.FLIGHT_CAR}
            packageConfig={packageConfig}
            packageWizardState={packageWizardState}
            togglePartialStay={props.togglePartialStay}
            setInputsRefs={props.setInputsRefs}
            globalConfig={globalConfig}
          />
        )}
        {!invalidPackageCombination && currentSubLOB === PackagesSubLobs.HOTEL_CAR && (
          <HotelCarHotwire
            {...props}
            currentSubLOB={currentSubLOB}
            packageType={PackagesSubLobs.HOTEL_CAR}
            packageConfig={packageConfig}
            packageWizardState={packageWizardState}
            togglePartialStay={props.togglePartialStay}
            setInputsRefs={props.setInputsRefs}
            globalConfig={globalConfig}
            carWizardState={carWizardState}
          />
        )}
        <PackageHiddenSearchDataHotwire {...packageHiddenSearchData} />
      </UitkLayoutGrid>
      {invalidPackageCombination && (
        <PackageErrorStateHotwire lobsMissing={lobsMissing} packageWizardState={packageWizardState} />
      )}
    </form>
  );
});
