import * as React from "react";
import { locKeys } from "../l10n";
import { ILOBMap, IPackageType, IPackageTypeMap, LobCombinations } from "../typings";
import { liveAnnounce } from "@egds/react-core/utils";
import { LocalizedComponentProps } from "@shared-ui/localization-context";
import { GlobalWizardState, PackageWizardState } from "src/stores/wizard/state";
import { FlightType } from "src/stores/wizard/state/typings";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

const {
  flightCheckboxAddLabel,
  flightCheckboxRemoveLabel,
  hotelCheckboxAddLabel,
  hotelCheckboxRemoveLabel,
  carCheckboxAddLabel,
  carCheckboxRemoveLabel,
  announcementMap,
  lobErrorHeadingsMap,
  lobErrorBodyMap,
} = locKeys;

export interface PackageTypeMenuProps extends LocalizedComponentProps {
  context: ExtendedContextStore;
  className?: string;
  lobState: PackageWizardState;
  onSubLOBSelection: (pwaSubLOB: string) => () => void;
  defaultPackageType: LobCombinations;
  updateLobsMissing: React.Dispatch<number>;
  minimumLobsNeeded: number;
  onFlightTypeUpdate: (flightType: FlightType) => void;
  packageSubLOBs: Map<string, string[]>;
  currentSubLOB?: string;
  showOneway: boolean;
  showMulticity: boolean;
  setFlightType: () => string;
  globalWizardState: GlobalWizardState;
}

export interface PackageTypeMenuState {
  lobMap: ILOBMap;
  error: boolean;
  useLink: boolean;
  packageTypeDefinition: IPackageTypeMap;
  activeSubLOB?: string;
  showEnhancedLabels?: boolean;
}

export interface WrappedComponentProps {
  lobsMap: ILOBMap;
  useLink: boolean;
  lobKeys: string[];
  handleMenuChange: (
    lobName: string,
    lobMenuItem: React.RefObject<any>,
    menuItemId: string,
    forceMenuItemState?: boolean | undefined
  ) => void;
}

export function withTypeMenu<T extends PackageTypeMenuProps = PackageTypeMenuProps>(
  WrappedComponent: React.ComponentType<WrappedComponentProps>
) {
  return class extends React.Component<PackageTypeMenuProps, PackageTypeMenuState> {
    constructor(props: PackageTypeMenuProps) {
      super(props);

      const { defaultPackageType, currentSubLOB, lobState, l10n, globalWizardState } = props;
      const { formatText } = l10n;
      const flightsType = "f";
      const hotelsType = "h";
      const carsType = "c";
      const {
        flightLabel,
        hotelsLabel,
        carsLabel,
        flightEnhancedLabel,
        hotelsEnhancedLabel,
        carsEnhancedLabel,
        hotelsEnhancedVariantLabel,
        carsEnhancedVariantLabel,
        flightEnhancedVariantLabel,
      } = lobState.config.lobLabels;
      const { includeSingleLOBsWithinPackages } = globalWizardState.config;
      this.state = {
        lobMap: {
          hotels: {
            label: formatText(hotelsLabel),
            enhancedLabel: formatText(hotelsEnhancedLabel),
            enhancedVariantLabel: formatText(hotelsEnhancedVariantLabel),
            altLabelAdd: formatText(hotelCheckboxAddLabel),
            altLabelRemove: formatText(hotelCheckboxRemoveLabel),
            checked: defaultPackageType.includes(hotelsType),
            ref: React.createRef(),
            id: `package-pills-hotels`,
            type: hotelsType,
          },
          flights: {
            label: formatText(flightLabel),
            enhancedLabel: formatText(flightEnhancedLabel),
            enhancedVariantLabel: formatText(flightEnhancedVariantLabel),
            altLabelAdd: formatText(flightCheckboxAddLabel),
            altLabelRemove: formatText(flightCheckboxRemoveLabel),
            checked: defaultPackageType.includes(flightsType),
            ref: React.createRef(),
            id: `package-pills-flights`,
            type: flightsType,
          },
          cars: {
            label: formatText(carsLabel),
            enhancedLabel: formatText(carsEnhancedLabel),
            enhancedVariantLabel: formatText(carsEnhancedVariantLabel),
            altLabelAdd: formatText(carCheckboxAddLabel),
            altLabelRemove: formatText(carCheckboxRemoveLabel),
            checked: defaultPackageType.includes(carsType),
            ref: React.createRef(),
            id: `package-pills-cars`,
            type: carsType,
          },
        },
        packageTypeDefinition: {
          flights: {
            checked: defaultPackageType.includes(flightsType),
            type: flightsType,
          },
          hotels: {
            checked: defaultPackageType.includes(hotelsType),
            type: hotelsType,
          },
          cars: {
            checked: defaultPackageType.includes(carsType),
            type: carsType,
          },
        },
        error: false,
        useLink: true,
        activeSubLOB: currentSubLOB,
        showEnhancedLabels: (includeSingleLOBsWithinPackages || lobState.config.showEnhancedLabels) ?? false,
      };

      this.lobKeys = this.props.packageSubLOBs.get("pills")!;
      this.packageTypeDefinition = Object.keys(this.state.packageTypeDefinition);
    }

    componentDidMount() {
      this.setState({ useLink: false });
    }

    private lobKeys: string[];

    private packageTypeDefinition: string[];

    componentDidUpdate() {
      const { currentSubLOB } = this.props;
      if (this.state.activeSubLOB !== currentSubLOB) {
        this.setState((state, props) => {
          const lobMap = state.lobMap;
          let numberOfSelectedLOBs = 0;

          this.lobKeys.forEach((lobKey) => {
            const packageType = lobKey.charAt(0);
            // @ts-ignore
            lobMap[lobKey].checked = currentSubLOB?.includes(packageType);

            // @ts-ignore
            if (lobMap[lobKey].checked) {
              numberOfSelectedLOBs += 1;
            }
          });

          const error = !Object.values(this.props.packageSubLOBs.get("lobsAllowed")!).some(
            (val) => val === currentSubLOB
          );
          let lobsMissing = 0;
          if (error) {
            lobsMissing = props.minimumLobsNeeded - numberOfSelectedLOBs;
            if (lobsMissing <= 0) {
              lobsMissing = 99;
            }
          }
          props.updateLobsMissing(lobsMissing);

          return {
            lobMap: { ...lobMap },
            activeSubLOB: props.currentSubLOB,
          };
        });
      }
    }

    flightsMapKey = "flights";

    public handleMenuChange = (
      lobName: string,
      lobMenuItem: React.RefObject<any>,
      menuItemId: string,
      forceMenuItemState?: boolean
    ) => {
      const { onSubLOBSelection, l10n, updateLobsMissing, minimumLobsNeeded } = this.props;

      this.setState((state: PackageTypeMenuState) => {
        const lobMap: ILOBMap = { ...state.lobMap };
        const packageTypeDefinition: IPackageTypeMap = { ...state.packageTypeDefinition };
        // Set new pills state
        const shouldForceMenuItemState = forceMenuItemState !== undefined && lobName === this.flightsMapKey;

        if (shouldForceMenuItemState) {
          lobMap[lobName as keyof ILOBMap].checked = forceMenuItemState;
          packageTypeDefinition[lobName as keyof ILOBMap].checked = forceMenuItemState;
        } else {
          lobMap[lobName as keyof ILOBMap].checked = !lobMap[lobName as keyof ILOBMap].checked;
          packageTypeDefinition[lobName as keyof IPackageTypeMap].checked = !packageTypeDefinition[
            lobName as keyof IPackageTypeMap
          ].checked;
        }

        //Announce new package type
        lobMap[lobName as keyof ILOBMap].checked
          ? liveAnnounce(lobMap[lobName as keyof ILOBMap].altLabelAdd)
          : liveAnnounce(lobMap[lobName as keyof ILOBMap].altLabelRemove);

        const lobCombination = this.packageTypeDefinition.reduce((previousValue, key) => {
          const lob: IPackageType = lobMap[key as keyof ILOBMap];

          return lob.checked ? `${previousValue}${lob.type}` : previousValue;
        }, "");

        const activeSubLOB = lobCombination || "none";

        let lobsMissing = 0;
        const error = !Object.values(this.props.packageSubLOBs.get("lobsAllowed")!).some((val) => val === activeSubLOB);

        const numberOfSelectedLOBs = this.lobKeys.filter((lob) => lobMap[lob as keyof ILOBMap].checked).length;
        if (!error) {
          liveAnnounce(l10n.formatText(announcementMap[activeSubLOB]));
          lobsMissing = 0;
        } else {
          lobsMissing = minimumLobsNeeded - numberOfSelectedLOBs;
          if (lobsMissing <= 0) {
            lobsMissing = 99;
          }
          const errorMessage = `${l10n.formatText(lobErrorHeadingsMap[lobsMissing])} : ${l10n.formatText(
            lobErrorBodyMap[lobsMissing]
          )}`;
          liveAnnounce(errorMessage);
        }

        onSubLOBSelection(activeSubLOB)();

        //TODO: change prop name
        updateLobsMissing(lobsMissing);

        return {
          lobMap,
          packageTypeDefinition,
          error,
          currentCheckboxErrorId: error ? menuItemId : undefined,
          currentCheckboxErrorRef: error ? lobMenuItem : null,
          useLink: state.useLink,
        };
      });
    };

    render() {
      const { lobMap: lobsMap, useLink, showEnhancedLabels } = this.state;

      return (
        <WrappedComponent
          {...(this.props as T)}
          lobsMap={lobsMap}
          useLink={useLink}
          lobKeys={this.lobKeys}
          showEnhancedLabels={showEnhancedLabels}
          handleMenuChange={this.handleMenuChange}
        />
      );
    }
  };
}
