import * as React from "react";
import { observer } from "mobx-react";

import { Dates } from "components/shared/Dates/Dates";
import { Location } from "components/shared/Location/Location";
import { SubmitButton } from "components/shared/SubmitButton/SubmitButton";
import { OffsetGridItem } from "components/shared/OffsetGridItem/OffsetGridItem";
import { useMultiLOBWizardRegion } from "components/flexComponents/WizardPWARegions/WizardRegionHotwire/WizardRegionContextProvider/MultiLOBWizardRegionContext";
import { PackagePartialStay } from "../../components/PackagePartialStay";
import { PackageSubLOBHotwireProps } from "../../typings";
import { getHotwireFlightHotelColSpans } from "../../utils/hotwirePackageOverrides";
import { MenuFieldsHotwire } from "./MenuFieldsHotwire";

export const FlightHotelHotwire = observer(
  ({ setInputsRefs, togglePartialStay, wizardState, currentSubLOB }: PackageSubLOBHotwireProps) => {
    const { isMultiLOB } = useMultiLOBWizardRegion();

    const {
      globalWizardState: { config: globalConfig },
      packageWizardState,
      packageWizardState: {
        config: {
          travelers: { noRehydratationValues },
        },
        isFlightHotelDefaultFormValid,
        updateInvalidFormsState,
        partialStay,
      },
    } = wizardState;

    if (isFlightHotelDefaultFormValid) {
      updateInvalidFormsState();
    }

    const {
      locationColSpan,
      datesColStart,
      datesColEnd,
      datesPartialStayColSpan,
      submitColSpan,
      multiLobNonPartialOffsetColSpan,
    } = getHotwireFlightHotelColSpans({ isMultiLOB, partialStay });

    return (
      <>
        <Location
          originField
          destinationField
          lobState={packageWizardState}
          colSpan={locationColSpan}
          setInputsRefs={setInputsRefs}
          showSwapLocationsControl={false}
          globalConfig={globalConfig}
        />
        <Dates
          lobState={packageWizardState}
          colStart={datesColStart}
          colEnd={datesColEnd}
          setInputsRefs={setInputsRefs}
        />
        <MenuFieldsHotwire
          currentSubLOB={currentSubLOB}
          wizardState={wizardState}
          noRehydratationValues={noRehydratationValues}
        />

        <PackagePartialStay
          togglePartialStay={togglePartialStay}
          packageWizardState={packageWizardState}
          partialStayAccommodationLabel="hotwire.wizard.package.partialStay.accommodation.checkbox.label"
        />

        {partialStay && (
          <Dates
            lobState={packageWizardState}
            isPartialStay
            colSpan={datesPartialStayColSpan}
            startId="d1-partial"
            endId="d2-partial"
            setInputsRefs={setInputsRefs}
            stackedDates={false}
          />
        )}
        {!partialStay && isMultiLOB && <OffsetGridItem colSpan={multiLobNonPartialOffsetColSpan} />}
        <SubmitButton
          lobState={packageWizardState}
          rfrr="SearchClick_Package"
          colSpan={submitColSpan}
          globalConfig={globalConfig}
        />
      </>
    );
  }
);
