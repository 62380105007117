import * as React from "react";

import { UitkText } from "@egds/react-core/text";
import { UitkSpacing, UitkSpacingProps } from "@egds/react-core/spacing";

import { useLocalization } from "@shared-ui/localization-context";

import { PackageConfig } from "stores/wizard/config";

export interface ValuePropSubtitleProps {
  config: PackageConfig;
  margin?: UitkSpacingProps["margin"];
}

export const ValuePropSubtitle: React.FC<ValuePropSubtitleProps> = ({ config, margin }) => {
  const { formatText } = useLocalization();

  const defaultMargin: UitkSpacingProps["margin"] = { blockstart: "four", blockend: "three" };

  return (
    <UitkSpacing margin={margin || defaultMargin}>
      <UitkText size={300}>{formatText(config.pillsLabelKey)}</UitkText>
    </UitkSpacing>
  );
};
