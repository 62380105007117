import * as React from "react";
import { PackagePartialStayProps } from "components/flexComponents/WizardPackagePWA/typings";
import { observer } from "mobx-react";
import { UitkCheckbox } from "@egds/react-core/checkbox";
import { UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { useLocalization } from "@shared-ui/localization-context";

export const PackagePartialStay = observer((props: PackagePartialStayProps) => {
  const { formatText } = useLocalization();

  return (
    <UitkLayoutGridItem colSpan="all">
      <div>
        <UitkCheckbox
          id="package-partial-stay"
          onChange={props.togglePartialStay}
          checked={props.packageWizardState.partialStay}
        >
          {formatText(props.partialStayAccommodationLabel)}
        </UitkCheckbox>
      </div>
    </UitkLayoutGridItem>
  );
});
